import axios from "axios";
import { useEffect, useState } from 'react';
import './App.css';

function App() {

  const[authorizationCode, setAuthorizationCode] = useState(null);

  const[accessToken, setAccessToken] = useState(null);
  const[availableDevices, setAvailableDevices] = useState([]);
  const[songId, setSongId] = useState("3aO4GaEhtu1UanIGKefdD3");
  const[deviceId, setDeviceId] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log("QueryParams", queryParams)
    const authorizationCode = queryParams.get('code');
    console.log("COde param", authorizationCode);
    setAuthorizationCode(authorizationCode);
}, []);

  
  const tryAuth= () => {
    console.log("So it begins...");

    // Redirect to 'https://accounts.spotify.com/authorize?'

    const authEndpoint = 'https://accounts.spotify.com/authorize?';
    const clientId = "7ca13a4215984b10960a1465a0c24bba";

    const redirectUri = "http://spotifyapi.dstowbox.co.uk";

    const scopes = [
      "user-read-currently-playing",
      "user-read-recently-played",
      "user-read-playback-state",
      "user-modify-playback-state",
    ];

    window.location = `${authEndpoint}client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}&response_type=code&show_dialog=true`;
  }

  const requestAccessToken = () => {

    const authEndpoint = 'https://accounts.spotify.com/api/token';
    const clientId = "7ca13a4215984b10960a1465a0c24bba";

    const redirectUri = "http://spotifyapi.dstowbox.co.uk";

    const params = new URLSearchParams();
params.append('grant_type', 'authorization_code');
params.append('code', authorizationCode);
params.append('redirect_uri', 'http://spotifyapi.dstowbox.co.uk');
// ... append other parameters as needed

axios.post(authEndpoint, params, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': 'Basic N2NhMTNhNDIxNTk4NGIxMDk2MGExNDY1YTBjMjRiYmE6OWM2YmFiYjA4YmQxNDhiZjhlNDVmM2I3MzVkODA1YmU='
  }
}).then(response => {
    console.log(response);
    setAccessToken(response.data.access_token);
});
  };

  const getAvailableDevices = () => {
axios.get("https://api.spotify.com/v1/me/player/devices", {headers: {"Authorization" : `Bearer ${accessToken}`}}).then(response => {
    console.log(response);
    setAvailableDevices(response.data.devices);
});
  };

  const startPlayback = () => {

    axios.put("https://api.spotify.com/v1/me/player/?deviceId=" + deviceId,
    {device_ids: [deviceId], play:true},
    {headers: {"Authorization" : `Bearer ${accessToken}`}}).then(response => {
      axios.put("https://api.spotify.com/v1/me/player/play?deviceId=" + deviceId,
      {uris: [`spotify:track:${songId}`]},
      {headers: {"Authorization" : `Bearer ${accessToken}`}}).then(response => {

      });
    });


  };

  return (
    <div className="s">
        <button onClick={tryAuth}>Get Auth Token</button>
        <button onClick={requestAccessToken}>Get Access Token</button>
        <p>Authorization Token</p>
        <h4>{authorizationCode}</h4>
        <p>Access Token</p>
        <h4>{accessToken}</h4>
        <button onClick={getAvailableDevices}>Get Available Devices</button>
        <br />
        <select value={deviceId} onChange={(e)=>{setDeviceId(e.target.value)}}>
          {availableDevices.length == 0 ? <option>No devices available (click button above)</option> : <option value={-1}>Select device...</option> }
          {availableDevices.map((device, index) => {
            return <option key={index} value={device.id}>{device.name} - {device.type}</option>
          })}
        </select>
        <br />
        <p>Song Id</p>
        <input type="text" placeholder="Enter a song id" value={songId} onChange={(e)=>{setSongId(e.target.value)}} />
        <p>{deviceId}</p>
        <button onClick={startPlayback}>Start playback</button>
    </div>
  );
}

export default App;
